import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, makeStateKey, TransferState } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    CoreUtils,
    ExternalInfluencer,
    FeatureScopeSandbox,
    Language,
    RxjsService,
    skipWhileEmpty,
    State,
    TranslateUtils,
} from '@smooved/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { CompanyPageUtils } from '../company-page.utils';
import { EXTERNAL_INFLUENCER_URI_CONFIG, ExternalInfluencerUriConfig } from '../configs';

interface GetExternalInfluencer {
    url: string;
    state: State;
    altState?: State;
    at?: string;
}

const realEstateGroupProfileKey = makeStateKey<ExternalInfluencer>('real-estate-group-profile');
const externalInfluencerKey = makeStateKey<ExternalInfluencer>('influencer');

@Injectable({
    providedIn: 'root',
})
export class ExternalInfluencerService extends RxjsService {
    public externalInfluencerSubject = new BehaviorSubject<ExternalInfluencer>(null);
    public externalInfluencer$ = this.externalInfluencerSubject.asObservable();

    public about$ = this.externalInfluencer$.pipe(
        skipWhileEmpty(),
        map((externalInfluencer) =>
            TranslateUtils.getAvailableLanguage(
                externalInfluencer.about,
                this.translate.currentLang as Language,
                externalInfluencer.aboutDefaultLanguage
            )
        )
    );

    public get externalInfluencer(): ExternalInfluencer {
        return this.externalInfluencerSubject.value;
    }

    constructor(
        private readonly httpClient: HttpClient,
        private readonly translate: TranslateService,
        private readonly transferState: TransferState,
        @Inject(EXTERNAL_INFLUENCER_URI_CONFIG) private readonly config: ExternalInfluencerUriConfig,
        private readonly featureScopeSandbox: FeatureScopeSandbox
    ) {
        super();
        this.listenForFeatureScopeChanges();
    }

    public getExternalInfluencer(regName: string): Observable<ExternalInfluencer> {
        return this.get({ url: regName, state: State.Published, altState: State.Unpublished });
    }

    public getExternalInfluencerFromTransferState(regName?: string): Observable<ExternalInfluencer> {
        const externalInfluencer = this.transferState.get<ExternalInfluencer>(externalInfluencerKey, null);
        if (this.transferState.hasKey(externalInfluencerKey) && externalInfluencer) {
            return of(externalInfluencer);
        } else if (regName) {
            return this.getExternalInfluencer(regName).pipe(
                tap((externalInfluencer) => {
                    this.transferState.set(externalInfluencerKey, externalInfluencer);
                })
            );
        }
    }

    public getRealEstateGroupProfile(getExternalInfluencer: GetExternalInfluencer): Observable<ExternalInfluencer> {
        const realEstateGroupProfile = this.transferState.get<ExternalInfluencer>(realEstateGroupProfileKey, null);
        if (this.transferState.hasKey(realEstateGroupProfileKey) && realEstateGroupProfile) {
            this.transferState.remove(realEstateGroupProfileKey);
            return of(realEstateGroupProfile);
        } else {
            return this.get(getExternalInfluencer).pipe(
                tap((data) => {
                    this.transferState.set(realEstateGroupProfileKey, data);
                })
            );
        }
    }

    private listenForFeatureScopeChanges(): void {
        this.externalInfluencer$.pipe(takeUntil(this.destroy$)).subscribe((externalInfluencer) => {
            this.featureScopeSandbox.dispatch({
                featureScopes: externalInfluencer?.featureScopes ?? [],
                featureScopesConfig: null,
            });
        });
    }

    public get({ url, state, altState, at }: GetExternalInfluencer): Observable<ExternalInfluencer> {
        if (this.transferState.hasKey(externalInfluencerKey)) {
            return of(this.transferState.get(externalInfluencerKey, null));
        } else {
            let params = new HttpParams().set('state', state.toString());
            params = params.append('language', this.translate.currentLang);
            if (!CoreUtils.isEmpty(altState)) params = params.append('altState', altState.toString());

            let headers = new HttpHeaders();
            if (state === State.Draft && at) {
                headers = headers.set('Authorization', `Bearer ${at}`);
            }
            const completeUri = this.config.byUrlUri.replace(':url', url);
            return this.httpClient
                .get<ExternalInfluencer>(completeUri, {
                    params,
                    headers,
                })
                .pipe(
                    tap((externalInfluencer) => {
                        this.transferState.set(externalInfluencerKey, externalInfluencer);
                    })
                );
        }
    }

    public set(externalInfluencer: ExternalInfluencer): void {
        this.externalInfluencerSubject.next(externalInfluencer);
    }

    public setLanguage(language: Language): void {
        /**
         * Fix: LocalizeRouterService.changeLanguage has a bug where root router parameters are not replaced by it's actual value
         * Quickest solution is to navigate ourselves.
         * https://github.com/Greentube/localize-router/pull/159#issuecomment-501331596
         *  */
        const languageRegexp = new RegExp(/\/(nl|en|fr)\//, 'g');
        const url = `${window.location.href}`;
        window.location.href = url.replace(languageRegexp, `/${language}/`);
    }

    public companyPageBaseUrlFragments(withLanguage = true): string[] {
        return [...(withLanguage ? [this.translate.currentLang] : []), this.externalInfluencer.url];
    }
}
